export const ONEDAYINSECONDS = 86400;
export const frontendurl = window.location.origin;
export const backurl = ""; // mainnet
export const BURN = "0x000000000000000000000000000000000000dEaD";
export const BLOCKS_PER_YEAR = 1051200;
// export const API_URL = "http://localhost:2056/"

// export const API_URL = "https://backend-spropad.maticz.in/"
// export const IMAGE_URL = "https://backend-spropad.maticz.in/"
// export const IMAGE_URL = "http://localhost:2056/"

export const API_URL = "https://api.smartpropad.com/"
export const IMAGE_URL = "https://api.smartpropad.com/"

// export const API_URL = "https://api.smartpropad.com/"
// export const IMAGE_URL = "https://api.smartpropad.com/"

export const NATIVETOKEN_SALE = "0x60A0941C792CB120A795B4BFDAeC82b3973f985D"

export const OWNER_ADDRESS = "0x66EE56A8FFb8Db5164B498813797797abeEF7AB2"
export const PROJECT_ID = "b8a1daa2dd22335a2fe1d2e139980ae0"


let rpcUrl = {
  RPC_URL1: "https://api.zan.top/node/v1/bsc/testnet/public",
  RPC_URL2: "https://bsc-testnet.public.blastapi.io",
  RPC_URL3: "https://endpoints.omniatech.io/v1/bsc/testnet/public",
  RPC_URL4: "https://data-seed-prebsc-2-s1.bnbchain.org:8545",
  RPC_URL5: "https://data-seed-prebsc-1-s3.bnbchain.org:8545",
  RPC_URL6: "https://bsc-testnet-rpc.publicnode.com",
  RPC_URL7: "https://data-seed-prebsc-1-s2.bnbchain.org:8545",
  RPC_URL8: "https://api.zan.top/node/v1/bsc/testnet/public",
  RPC_URL9: "https://bsc-testnet.blockpi.network/v1/rpc/public"
}

export const CHAINS = [
  {
    NAME: "BSC-MAINNET",
    RPC_URL: "https://bsc-dataseed1.binance.org/",
    CHAIN_ID: 56,
    TOKENADDRESS: "0xBb8ebAddbdd88995485fdB224C3C956555034bd7", //LBM
    USDTADDRESS: "0x55d398326f99059fF775485246999027B3197955", // USDTADDRESS
    NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B",
    MASTERCHEF: "0x43a16a065b63E6Bdc90d7FFaFe7461d823C70194", // launchpad proxy
    LOCKEDSTAKING: "0x14b878C5A4b02a1a7b0aEd530A757F982e996e88",// "0x4fd04a61e8490813f65d7820bf1b69ea1250fa0d", // locked staking
    USDT : "0x55d398326f99059fF775485246999027B3197955",
  },
  // {
  //   NAME: "BSC-TESTNET",
  //   RPC_URL: "https://bsc-testnet-rpc.publicnode.com",// rpcUrl.RPC_URL6,
  //   CHAIN_ID: 97,
  //   TOKENADDRESS: "0xDFCd0b91AE2BB34a90D9b4970Dc113DFaf25004d", //LBM
  //   USDTADDRESS: "0x0A1F4c4E14Dd83018942c5813768A8bf70b78599", // USDTADDRESS
  //   NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B",
  //   MASTERCHEF: "0x43a16a065b63E6Bdc90d7FFaFe7461d823C70194", // launchpad proxy
  //   // LOCKEDSTAKING: "0xD315dFb06fB3bA82309f0cE6b9EEb256E3953cE0", // locked staking
  //   LOCKEDSTAKING: "0x6D4306f5439793E3AEA6C0AfD0f7849211F8Ea55"//"0x36BE63BC0D31dC2fF33EEFD4524C7994b58C8Ade" //"0x85C3a5EB916e009492686FA9c5FEf14AF9411690", // locked staking
  // }
];


